import React, { useState } from "react"
import ServicesGrid from "../layout/ServicesGrid/ServicesGrid"
import AccordionList from "../layout/AccordionList/AccordionList"
import ExperienceSection from "../layout/ExperienceSection/ExperienceSection"
import TickerRun from "../layout/TickerRun/TickerRun"
import BlocksWithStars from "../layout/BlocksWithStars/BlocksWithStars"
import OurExpertise from "../layout/OurExpertise/OurExpertise"
import SolutionBlock from "../layout/SolutionBlock/SolutionBlock"
import PageNameAndImage from "../layout/PageNameAndImage/PageNameAndImage"
import OurWorksCarousel from "../Carousel/OurWorksCarousel/OurWorksCarousel"

const featuresByPage = new Map([
  ["Web Development", ["Wide Expertise", "Full-stack", "Result Oriented"]],
  ["Dedicated Developers", ["Personal Approach", "Pro Team", "Flexibility"]],
  ["Mobile App Development", ["Idea", "Design", "Develop"]],
  [
    "Solutions Development",
    ["Best Practises", "Result-focused", "Exceed Expectations"],
  ],
])
const solutionsByPage = new Map([
  [
    "Web Development",
    {
      title: "Web Expertise",
      text:
        "Finding a professional team that provides you full-stack development services usually takes much more time" +
        " and effort. Also, it is important to keep in mind that web development is not just about creating a" +
        " website.\n Modern web development is about using the latest technologies and approaches, which help you to" +
        " build responsive, effective and SEO-optimized web solutions. That's why hiring an appropriate team for" +
        " your project is a complicated task.\n Our team is ready to embody your ideas into reality and provide you with the best web solution. A result-oriented approach and wide expertise allow us to provide perfect web development services that will help you to scale your business and make your company more successful.",
    },
  ],
  [
    "Dedicated Developers",
    {
      title: "Expert Team",
      text:
        "When you hire dedicated developers you always get what you want. You stay focused on your ideas and the team brings them into life.\n" +
        "Our secret is hiring the best candidates. The screening processes we developed let us hire perfect candidates with good skills and problem-solving abilities. A properly assembled team gives synergy to make successful projects.\n High skills, dedication, partnership and flexibility allow us to face complex challenges and develop quality solutions. Our staff of designers, developers, managers, analysts allows us to take on a project as soon as possible and complete them on time.",
    },
  ],
  [
    "Mobile App Development",
    {
      title: "Mobile Expertise",
      text:
        "Quality mobile application is a key to customers in any industry. Intuitive and friendly design, high performance and security are base requirements for a quality application.\n Our mobile development team is ready to provide you with the best mobile development solutions for Android, iOS and cross-platform mobile apps. We also provide mobile apps maintenance. If you have an idea about creating a native or hybrid mobile application — don’t hesitate to contact us.",
    },
  ],
  [
    "Solutions Development",
    {
      title: "Implementing Ideas",
      text:
        "Our task is to create a custom solution to your idea. You can fully rely on our experience and expertise in creating efficient products from scratch. Our confidence is backed up by best practices and a vast tech stack.\n In order to meet the customers' needs the entire team, including project managers, analysts, designers and developers is involved. Our experts are ready to exceed your expectations.",
    },
  ],
])
const accordionArray = [
  {
    id: 1,
    title: "Experience",
    text:
      "The people you’ll meet in our team have solid working experience and are experts in business, design and technology. Together you'll discuss ideas and opportunities, and we'll deliver blueprints for the best solution.",
  },
  {
    id: 2,
    title: "Result-oriented",
    text:
      "We are not afraid of complicated tasks and are ready for new challenges. Our team is always focused on achieving the result in order to create a high quality solution for the client.",
  },
  {
    id: 3,
    title: "Vast tech stack",
    text:
      "Building a complex project calls for using a lot of advanced technologies. Our team is ready to provide you the top-notch tech solutions in order to meet all your requirements and expectations.",
  },
  {
    id: 4,
    title: "Continuous support",
    text:
      "You are not alone after the release stage. We are ready to maintain and scale your project. Our main goal is to build long-term trusted relationships with our customers.",
  },
]

const DevelopmentPage = ({ pageTitle, imagePath }) => {
  const [blocksArrayWebDev] = useState([
    { id: 1, title: "UX/UI Design" },
    { id: 2, title: "DevOps Enablement" },
    { id: 3, title: "Full-Stack Development" },
    { id: 4, title: "Legacy Сode Refactoring" },
    { id: 5, title: "Long-term Support and Maintenance" },
    { id: 6, title: "Test Driven Development" },
  ])
  const [blocksArrayMobDev] = useState([
    { id: 1, title: "Android Development" },
    { id: 2, title: "iOS Development" },
    { id: 3, title: "Cross-platform" },
    { id: 4, title: "Augmented Reality Apps" },
    { id: 5, title: "App Maintenance" },
    { id: 6, title: "Engaging UX" },
  ])
  const [blocksArrayDedicated] = useState([
    { id: 1, title: "Meeting Deadlines" },
    { id: 2, title: "Fast Start" },
    { id: 3, title: "Cost-efficiency" },
    { id: 4, title: "Customer Support" },
    { id: 5, title: "Complete Confidentiality" },
    { id: 6, title: "Trusted Relationships" },
  ])
  const [blocksArraySolution] = useState([
    { id: 1, title: "Custom Solutions" },
    { id: 2, title: "Vast Stack Range" },
    { id: 3, title: "Developing From Scratch" },
    { id: 4, title: "Meeting Customers Needs " },
    { id: 5, title: "Agile Approach" },
    { id: 6, title: "MVP Development" },
  ])

  const [experienceArray] = useState([
    { id: 1, counter: 8, subtitle: "Years of Experience" },
    { id: 2, counter: 86, subtitle: "Full-Time Engineers" },
    { id: 3, counter: 172, subtitle: "Successful Projects" },
    { id: 4, counter: 115, subtitle: "Customers Worldwide" },
  ])

  const [starsArray] = useState([
    {
      id: 1,
      platform: "clutch",
      stars: 5,
      reviews: 6,
      link: "https://clutch.co/profile/drumncode",
    },
    {
      id: 2,
      platform: "upwork",
      stars: 5,
      reviews: 38,
      link: "https://www.upwork.com/o/companies/~016c0f5817577c5b5e/",
    },
  ])

  const [expertiseArray] = useState([
    {
      id: 1,
      title: "iOS App Development",
      text:
        "We have all the expertise you need to produce fully-fledged, stable, and scalable mobile applications, including:",
      techList: [
        "Swift",
        "OBJ-C",
        "Swift UI",
        "MapKit",
        "CoreAnimation",
        "ARKit",
        "StoreKit",
        "CallKit",
      ],
    },
    {
      id: 2,
      title: "Android App Development",
      text:
        "Using the latest technologies, we help businesses establish their presence on any modern device and platform, including:",
      techList: [
        "Kotlin",
        "Android SDK",
        "Google Play Services",
        "Google API",
        "Custom Views",
        "Firebase",
        "Android Jetpack",
        "KOIN",
        "Hilt",
      ],
    },
  ])

  return (
    <>
      <PageNameAndImage
        name={pageTitle}
        imagePath={imagePath}
        features={featuresByPage.get(pageTitle)}
        isShowIcon={false}
      />

      <div className={`container`}>
        {pageTitle === "Web Development" && (
          <ServicesGrid blocks={blocksArrayWebDev} />
        )}
        {pageTitle === "Mobile App Development" && (
          <ServicesGrid blocks={blocksArrayMobDev} />
        )}
        {pageTitle === "Dedicated Developers" && (
          <ServicesGrid blocks={blocksArrayDedicated} />
        )}
        {pageTitle === "Solutions Development" && (
          <ServicesGrid blocks={blocksArraySolution} />
        )}

        <AccordionList title="Why We" list={accordionArray} />

        <ExperienceSection title="Interesting data" list={experienceArray} />
      </div>

      <TickerRun yellow stringTicker text="Let’s Talk" />

      {(pageTitle === "Web Development" ||
        pageTitle === "Dedicated Developers") && <OurWorksCarousel />}

      <div className={`container`}>
        <BlocksWithStars
          titleSection="What Our Clients Say"
          list={starsArray}
        />

        {pageTitle === "Mobile App Development" && (
          <>
            <OurExpertise title="Our Expertise" list={expertiseArray} />
          </>
        )}

        <SolutionBlock solutionText={solutionsByPage.get(pageTitle)} />
      </div>

      <TickerRun yellow={false} text="Get in Touch" stringTicker />
    </>
  )
}

export default DevelopmentPage
